@font-face{
    font-family : 'Pretendard';
    src: url('../fonts/Pretendard.otf') format('truetype')
}

html { font-size: 14px; }
@media (max-width: 800px){ html { font-size: 12px; } }

.App {
    width: 100vw;
    overflow-x:hidden; 
    overflow-y:auto;
    background-color: #fff;
    text-align: center;
}

.App-Head {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 74px;
    background-color: rgba(255,255,255,1);
    border-bottom: solid 0.5px #000;
    z-index: 100;
}

.App-Body {
    margin-top: 74px;
}

.App-Bottom {
    width: 100vw;
    height: 140px;
    background-color: #000;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center
}
